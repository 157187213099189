import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import { CustomHeader } from "./CustomHeader";
import { CustomTableContent } from "./CustomTableContent";
import { CustomTableFooter } from "./CustomTableFooter";
import { CustomPagination } from "../ui/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../actions/sentAction";

export const CustomTable = ({ notifications }) => {
  const dispatch = useDispatch();
  const { pagination, elementsCount } = useSelector(
    (state) => state.sentReducer
  );

  const totalPages = Math.ceil(elementsCount / 10);

  const handleOnChangePagination = (event, value) => {
    const body = {
      count: "10",
      pag: value,
      sort: { _id: -1 },
    };
    getNotifications(body)(dispatch);
  };

  return (
    <>
      <TableContainer>
        <Table
          sx={{
            borderCollapse: "separate",
            borderSpacing: "0px 15px",
            tableLayout: "fixed",
            width: "100%",
          }}
        >
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "40%", maxWidth: "45%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>

          
          <TableHead>
            <TableRow>
              <CustomHeader text="Remitente" />
              <CustomHeader text="Asunto" />
              <CustomHeader text="Estado" />
              <CustomHeader text="Fecha" />
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.map((row, index) => (
              <CustomTableContent row={row} key={index} />
            ))}
          </TableBody>
        </Table>
        <CustomTableFooter
          pagination={pagination}
          elementsCount={elementsCount}
          showing={notifications.length}
        />
        <CustomPagination
          pagination={pagination}
          handleOnChange={handleOnChangePagination}
          totalPages={totalPages}
        />
      </TableContainer>
    </>
  );
};
