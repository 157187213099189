import axios from 'axios'

import { GET_NOTIFICATIONS } from '../types/type'
import { API_ENDPOINT } from '../config/config'
import { GET_LIST_NOTIFICATIONS } from './actionsUtilities/actionRoutes'
  
export const getNotifications = (body) => async(dispatch)=> {
    const headers = { 'Authorization': localStorage.getItem('jwtToken') }
    await axios.post(
      `${API_ENDPOINT+GET_LIST_NOTIFICATIONS}`,
      body,
      { headers }
    ).then(res => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: { notifications: res.data.result.notifications, elementsCount: res.data.result.elementsCount,pagination:body.pag}
      })
    }).catch(async reason => {
    //   await reasonDispatch(dispatch, reason, false)
        console.log(reason);
    })
  }