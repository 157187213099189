import React from 'react'
import { styled } from "@mui/material/styles"
import { Typography, Button, FormControlLabel, Checkbox, Link, Box, Container } from '@mui/material'
import TextFieldComponent from '../../Input/TextFieldComponent'
import { Field, Form, Formik } from 'formik'
import { object, string } from 'yup'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../../actions/authActios'
import { useNavigate } from 'react-router-dom'
import { CheckBoxLogin } from '../../Input/CheckBoxLogin'

const CustomCheckbox = styled(Checkbox)`
    
`
const Label = styled(FormControlLabel)`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  line-height: 15px;
  color: #878787;
  margin-left: 0px !important; 
`
const LinkForgot = styled(Link)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-decoration-line: underline;
  color: #2499EF;
`
const Content = styled(Container)`
  padding: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
`
const ButtonSend = styled(Button)`
  width: 486px;
  height: 50px;
  background: #2499EF;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  text-transform: none;

  :hover {
    color: #2499EF;
    border: 1px solid #2499EF;
  }
`
const Text = styled(Typography)`
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #5F748D;
  text-align: center
`

const initialValues = {
  email: "",
  password: "",
}
export const LoginForm = () => {

  const dispatch =  useDispatch()
  const navigate = useNavigate()

  const setLogin = (values) => {
    dispatch(loginUser(navigate, values))
  }

  return (
    <Formik 
      initialValues={initialValues} 
      onSubmit={(values, formikHelpers) => {
        setLogin(values);
        formikHelpers.resetForm()
      }}
      validateSchema={object({
        email: string().required("Campo Obligatorio").email("Formato invalido"),
        password: string().required("Campo Obligatorio"),
      })}
    >
      {
        ({errors, isValid, touched, dirty}) => (
          <Form>
            <Box height={14}/>
            <Field
              name="email"
              type="email"
              as={TextFieldComponent}
              variant="outlined"
              label="Usuario"
              fullWidth
              error={Boolean(errors.email) && Boolean(touched.email)}
              helperText={Boolean(touched.email) && errors.email}
            />
            <Box height={14}/>
            <Box height={14}/>
            <Field
              name="password"
              type="password"
              as={TextFieldComponent}
              variant="outlined"
              label="Contraseña"
              fullWidth
              error={Boolean(errors.password) && Boolean(touched.password)}
              helperText={Boolean(touched.password) && errors.password}
            />
            <Box height={14}/>
            <Content>
              <Label control={<CheckBoxLogin />} label="Recuerdame" />
              <LinkForgot>Olvidé la contraseña</LinkForgot>
            </Content>
            <Box height={14}/>
            <ButtonSend type="submit">Ingresar</ButtonSend>
            <Box height={14}/>
            <Text>Versión 1.0-Prueba-despliegue</Text>
          </Form>
        )
      }
    </Formik>
  )
}
