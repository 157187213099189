import { combineReducers } from 'redux'
import authReducer from './authReducer'
import modalReducer from './modalReducer'
import sentReducer from './sentReducer'
import writeReducer from './writeReducer'
import errorReducer from './errorReducer'

export default combineReducers({
    authReducer,
    /*modalReducer,
    sentReducer,
    writeReducer,*/
    errorReducer
})