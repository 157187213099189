import { Tab, Tabs } from '@mui/material'
import React from 'react'
import { styled } from "@mui/material/styles";

const CustomTab = styled(Tab)`
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 13px;
`;

export const SendTabs = ({sx}) => {
  return (
    <Tabs sx={sx}
        variant="standard"
        value={0}
        // onChange={handleChange}
        aria-label="basic tabs example"
      >
        <CustomTab label="Todas" wrapped sx={{mr:2,textTransform: "none"}}/>
        <CustomTab label="Confirmacíon de lectura" wrapped sx={{mr:2,textTransform: "none"}} />
        <CustomTab label="Firma de notificación" wrapped sx={{mr:2,textTransform: "none"}} />
        <CustomTab label="Firma de documentos" wrapped sx={{textTransform: "none"}} />
      </Tabs>
  )
}



