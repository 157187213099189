import { Stack } from "@mui/system";
import React from "react";
import { styled } from '@mui/material/styles';
import { Pagination } from "@mui/material";

export const StyledPagination = styled(Pagination)(() => ({
  "& .MuiPaginationItem-page.Mui-selected": {
    fontWeight: 600,
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#2499EF",
    color: "white",
    "&:hover": {
      backgroundColor: "#2499EF",
    }
  },
  "& .MuiPaginationItem-page, & .MuiPaginationItem-page.Mui-selected > button": {
    fontFamily: "Montserrat !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "14px !important", 
    color: "#738499",
  },
  
}));

export const CustomPagination = ({handleOnChange,totalPages}) => {
  return (
    <Stack alignItems="center" marginY={4}>
      <StyledPagination
        onChange={handleOnChange}
        shape="rounded"
        count={totalPages}
        boundaryCount={1}
        siblingCount={1}
        
        
      />
    </Stack>
  );
};
