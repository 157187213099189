import React from "react"
import { styled } from "@mui/material/styles"
import Container from '@mui/material/Container'

const Content = styled(Container)`
  width: calc(100% - 300px);
  margin-left: 260px;
  margin-right: 0px;
  box-sizing: unset;
`
const AppContent = (props) => {
  return (
    <Content maxWidth={false} >
      {props.children}
    </Content>
  );
};

export default AppContent;
