//token prefix eg. 'Bearer ' or 'Token '
export const TOKEN_PREFIX = 'Bearer '
export const API_ENDPOINT_MYSELF = process.env.NODE_ENV === 'production' ?
    'https://qa-b.gse.com.co' : 
    'https://qa-b.gse.com.co'
export const API_ENDPOINT = process.env.NODE_ENV === 'production' ?
    'https://demo-t.gse.com.co' :
    'https://demo-t.gse.com.co'
export const API_ENDPOINT_AUTH_DEV = process.env.NODE_ENV === 'production' ?
    'http://localhost:9015' :
    'http://localhost:9015'
export const API_ENDPOINT_EVENT_DEV = process.env.NODE_ENV === 'production' ?
    'http://localhost:9022' :
    'http://localhost:9022'
export const API_ENDPOINT_NOTIFICATION_DEV = process.env.NODE_ENV === 'production' ?
    'http://localhost:9017' :
    'http://localhost:9017'
export const API_ENDPOINT_PROVIDER_DEV = process.env.NODE_ENV === 'production' ?
    'http://localhost:9018' :
    'http://localhost:9018'
export const API_ENDPOINT_SIGNING_DEV = process.env.NODE_ENV === 'production' ?
    'http://localhost:9020' :
    'http://localhost:9020'
export const API_FILE_DEV = process.env.NODE_ENV === 'production' ?
    'http://localhost:9019' :
    'http://localhost:9019'
export const API_FILE = process.env.NODE_ENV === 'production' ?
    'https://demo-t.gse.com.co' :
    'https://demo-t.gse.com.co'
export const defaultResponses = {
    noResponseFromApi: 'No hay respuesta por parte del servidor. Por favor intente de nuevo más tarde.'
}
export const configMoment = {
    months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
    monthsShort: 'ene_feb_mar_abr_may_jun_jul_ago_sept_oct_nov_dec'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'dom_lun_mar_mier_jue_vier_sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
}