import React from 'react'
import { styled } from "@mui/material/styles"
import {ReactComponent as ImageValidateSuccess} from '../../assets/Plantilla/imageValidateSuccess.svg'
import {ReactComponent as ImageErrorEmail} from '../../assets/Plantilla/imageErrorEmail.svg'
import {ReactComponent as ImageErrorAuth} from '../../assets/Plantilla/imageErrorAuth.svg'
import {ReactComponent as ErrorIcon} from '../../assets/ErrorMessage/error.svg'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { CustomComponent } from '../ui/CustomComponent'
import { CardError } from './CardError'

const CardComponent = styled(Card)`
  display: flex; 
  height: 309px; 
  align-items: center; 
  border-radius: 8px; 
  box-shadow: 0px 4px 10px 2px #13507C0F;

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
    height: auto;
    width: 80%;
  }
  @media only screen and (max-width: 414px) {
    flex-direction: column;
    height: auto;
    width: 320px;
  }
`
const Container = styled(Box)`
  background: #E9EFF6;
  height: 100%;
  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
`
const BoxContent = styled(Box)`
  display: flex;
  flex-direction: column;  
  width: 444px;

  @media only screen and (max-width: 1023px) {
    width: 100%;
    height: 300px;
    padding: 32px, 22px, 32px, 22px;
  }
`
const Title1 = styled(Typography)`
  color: #011E3D;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26.82px;
`
const Title2 = styled(Typography)`
  color: #011E3D;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39.01px;
  margin-bottom: 5px;
  
  @media only screen and (max-width: 1023px) {
    font-size: 42px;
    padding-bottom: 20px;
  }
  @media only screen and (max-width: 414px) {
    font-size: 32px;
    line-height: initial;
  }
`
const Title3 = styled(Typography)`
  color: #335474;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.94px;
  display: flex;

  @media only screen and (max-width: 1023px) {
    font-size: 32px;
    line-height: initial;
  }
  @media only screen and (max-width: 414px) {
    font-size: 22px;
    line-height: initial;
  }
`
const Title4 = styled(Typography)`
  color: #335474;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21.94px;
  padding-left: 5px;
  padding-right: 5px;
`
const data = {
  'ImageValidateSuccess': {
    image: ImageValidateSuccess,
  },
 'ImageErrorEmail': {
    image: ImageErrorEmail,
  },
  'ImageErrorAuth': {
    image: ImageErrorAuth,
  },
  'default': {
    image: ErrorIcon,
  }
}
export const CardWelcome = (props) => {
  const imageData = data[props.stateMessage .image] ? data[props.stateMessage.image] : data['default']

  const HtmlToReactParser = require('html-to-react').Parser;
  const htmlToReactParser = new HtmlToReactParser();
  const reactElement = (htmlInput) => {
    return htmlToReactParser.parse(htmlInput);
  }
  return (
    <>
     {
       props.stateMessage
       ?
        <CardComponent>
            <Container>
              <CustomComponent component={imageData.image} />
            </Container>
            <BoxContent>
              <CardContent>
                <Title1>
                  {props.stateMessage.title}
                </Title1>
                <Title2>
                  {props.stateMessage.subTitle}
                </Title2>
                <Title3>
                  {reactElement(`${props.stateMessage.message}`)}
                </Title3>
              </CardContent>
            </BoxContent>
          </CardComponent>
        : <CardError/>
      }
    </>
  )
}

