import cogoToast from "cogo-toast"
import { defaultResponses } from "../config/config"
import { FORM_ERROR, GET_ERRORS } from "../types/type"
import { toastOptions, toastOptionsTime } from "../helpers/globalHelpers"

export const reasonDispatch = (dispatch, reason, toast = true) => {
  if (reason.response && reason.response.data && reason.response.data.result) {
    const res = reason.response
    const result = reason.response.data.result
    switch (res.status) {
      case 400:
        if (toast) cogoToast.info(result.name || (result.suppressedEmails && result.suppressedEmails.message) || (result.errors && result.errors.error) || result || 'Error', toastOptionsTime)
        if (!result) console.error(result)
        if (result.errors) {
          console.log('400')
          dispatch({
            type: FORM_ERROR,
            payload: result.errors
          })
        } else {
          console.log(res.data.result,'40',defaultResponses.noResponseFromApi)
          dispatch({
            type: GET_ERRORS,
            payload: !!res
              ? res.data.result
              : { message: defaultResponses.noResponseFromApi }
          })
        }
        break
      case 422:
        if (toast) cogoToast.error(result.name || (result.suppressedEmails && result.suppressedEmails.message) || (result.errors && result.errors.error) || 'Error en el formulario.', toastOptions)
        if (result.errors) {
          dispatch({
            type: FORM_ERROR,
            payload: result.errors
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: !!res
              ? res.data
              : { message: defaultResponses.noResponseFromApi }
          })
        }
        break
      case 413:
        console.error(res.statusText)
        dispatch({
          type: FORM_ERROR,
          payload: { 'error': res.statusText }
        })
        break
      default:
        dispatch({
          type: GET_ERRORS,
          payload: !!res
            ? res.data
            : { message: defaultResponses.noResponseFromApi }
        })
        break
      /*case 500:
        if (toast) cogoToast.info(result.name || (result.suppressedEmails && result.suppressedEmails.message) || (result.errors && result.errors.error) || result || 'Error', toastOptionsTime)
        if (!result) console.error(result)
        if (result.errors) {
          dispatch({
            type: FORM_ERROR,
            payload: result.errors
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: !!res
              ? res.data.result
              : { message: defaultResponses.noResponseFromApi }
            })
          }
          break*/
    }
  } else if (reason.code === 'ERR_NETWORK') {
    dispatch({
      type: FORM_ERROR,
      payload: {statusMessage: 'ERR_NETWORK'}
    })
  } else {
    if(reason.statusCode === 200 && reason.result.success ===false){
      dispatch({
        type: FORM_ERROR,
        payload: reason.result
      })
    }
    if (toast) cogoToast.error('Error', toastOptions)
    console.error(reason)
  }
}