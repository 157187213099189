import React from 'react'
import { BrowserRouter, 
         Navigate, 
         Route, 
         Routes
        } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ProtectedRoutes from './ProtectedRoutes'
import { Login } from '../view/Auth/Login'
import { Sents } from '../view/Sent/Sents'
import { Write } from '../view/Write/Write'
import { Dashboard } from '../view/Dashboard/Dashboard'
import Welcome from '../view/Auth/Welcome'

const AppRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.authReducer)
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login/>} />
        <Route path='/validate-email/:tokenUser' element={<Welcome/>}/>
        <Route element={<ProtectedRoutes/>}>
          <Route path='/sent' element={<Sents/>}/>
          <Route path="/write" element={<Write/>}/>
          <Route path='/dashboard' element={<Dashboard/>}/>
        </Route>
        <Route path='*' element={isAuthenticated ? <Navigate to='/sent'/> : <Navigate to='/'/>}/>
      </Routes>
    </BrowserRouter>
  )
}
export default AppRoutes

