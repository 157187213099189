import React, { useEffect, useState } from 'react'
import { styled } from "@mui/material/styles"
import Box from '@mui/material/Box'
import Header from '../../components/Plantilla/Header'
import { CardWelcome } from '../../components/Plantilla/CardWelcome'
import { useDispatch, useSelector } from 'react-redux'
import { getValidateEmail } from '../../actions/authActios'
import { useParams } from 'react-router-dom'

const Container = styled(Box)`
  width: 812px;
  margin: auto;
  margin-top: 14%;
  
  @media only screen and (max-width: 1023px) {
    display: flex;
    justify-content: center;
    margin-top: 20%;
    width: 100% !important;
  }
`
const Welcome = () => {

  const dispatch = useDispatch()
  const {tokenUser} = useParams()
  const {errors} = useSelector((state) => state.errorReducer)
  const {showError} = useSelector((state) => state.errorReducer)
  const {messageVerificate} = useSelector((state) => state.authReducer)
  const [status, SetStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [statusErrorMessage, setStatusErrorMessage] = useState()

  useEffect(() => {
    const body = {"token": tokenUser}
    dispatch(getValidateEmail(body)).then(()=>{
      SetStatus(true)
    })
  }, [])
 
  return (
    <>
      <Header/>
      <Container>
        {
          status === true 
          &&
          <CardWelcome
            stateMessage={showError === true ? errors.result.result : messageVerificate.result}
            statusErrorMessage={statusErrorMessage}
          />
        }
      </Container>
    </>
  )
}

export default Welcome