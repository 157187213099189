import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { API_ENDPOINT, 
         API_ENDPOINT_MYSELF,
         API_ENDPOINT_AUTH_DEV } from "../config/config"
import { SET_CURRENT_USER,
         LOGOUT_USER, 
         VALIDATE_EMAIL_USER,
         CLEAR_ERRORS
} from '../types/type'
import { LOGIN_USER_ROUTE, VALIDATE_EMAIL } from './actionsUtilities/actionRoutes'
import setAuthToken from '../utils/setAuthToken'
import { reasonDispatch } from "./dispatchGeneric"
/**
 * AuthAction loginUser ( set user credentials to server for login attempt )
 *
 * @param {*} userData
 */
export const loginUser = (navigate, userData) => async (dispatch) => {
    await axios.post(`${API_ENDPOINT+LOGIN_USER_ROUTE}`, 
      userData)
      .then(res => {
        const { token, changePassword } = res.data.result
        setAuthToken(token)
        const decoded = jwt_decode(token)
        dispatch(setCurrentUser(decoded))
           if (navigate) {
              //if (changePassword) {
                navigate('/sent')
            //} else {
            /*history.push(firstViewByRole(decoded.roleName))*/
            //}
      }
    }).catch(console.error)
}

/*const firstViewByRole = (roleName) => {
    return { pathname: '/sent' }
  }*/

export const setCurrentUser = decoded => {
    return {
      type: SET_CURRENT_USER,
      payload: decoded
    }
}

export const logoutUser = (navigate) => async (dispatch) => {
    setAuthToken(false)
    dispatch(setCurrentUser({}))
    if (navigate) navigate('/')
    dispatch({
      type: LOGOUT_USER
    })
}

export const getValidateEmail = (body) => async (dispatch) => {
  await axios.post(`${API_ENDPOINT_MYSELF}${VALIDATE_EMAIL}`,
  body
  ).then(res => {
    dispatch({
      type: VALIDATE_EMAIL_USER,
      payload: res.data.result
    })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}