import React from 'react'
import { styled } from "@mui/material/styles"
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ReactComponent as LogoMenu } from '../../assets/Menu/logoMenu.svg'
import { ReactComponent as ViñetaMenu } from '../../assets/Menu/viñeta.svg'
import { ButtonWrite } from '../Buttons/ButtonWrite'
import Container from '@mui/material/Container'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../actions/authActios'

const drawerWidth = 260;

const ButtonMenu = styled(ListItemButton)`
  margin-left: 20px;
  '&:hover': {
    background: #F1F6F9;
  }
  '&:active':  {
    background: #F1F6F9;
  }
  &:focus {
    background: #F1F6F9;
  }
`

const TextMenu = styled(ListItemText)`
  
  line-height: 17px;
  color: #011E3D;
  span{
    font-family:'Montserrat';
    font-style: normal; 
    font-weight: 600;
    font-size: 14px;
  }
`
const IconList = styled(ListItemIcon)`
  min-width: 16px;
  svg {
    fill: #2499EF;
  }

  path{
      fill: #2499EF;
  }
`
const DividerLogo = styled(Container)`
 margin-top: 24px;
`
const DividerButton = styled(Container)`
 margin-top: 24px;
`

export const MenuLeft = (props) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(logoutUser())
  }
  return (
    <Box sx={{ display: 'flex' ,borderRight:"none"}}>
      <Drawer
        sx={{
          width: drawerWidth,
          "& .MuiPaper-root": {
            borderWidth:0
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <DividerLogo>
          <LogoMenu onClick={handleClick}/>
        </DividerLogo>
        <DividerButton>
          <ButtonWrite/>
        </DividerButton>
        <nav>
        <List>
          {
          props.menuRoutes &&
          props.menuRoutes.map((text, index) => (
            <ListItem key={index }   disablePadding>
              <ButtonMenu
                key={index}
                component={NavLink}
                to={text.route} 
              >
                <IconList>
                  <ViñetaMenu/>
                </IconList>
                <TextMenu primary={text.title} sx={{color: "#2499EF"}}  />
              </ButtonMenu>
            </ListItem>
          ))}
        </List>
        </nav>
      </Drawer>
    </Box>
  )
}
