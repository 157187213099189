//CONSTANTES
const VERSION1 = '/v1'
const VERSION2 = '/v2'

const AUTH_ROUTE = '/authentication'
const NOTIFICATION_ROUTE = '/notification'

//AUTH 
export const LOGIN_USER_ROUTE = AUTH_ROUTE+VERSION1+'/login'
export const VALIDATE_EMAIL = '/user'+VERSION1+'/register/validate-email'

//SENTS
export const GET_LIST_NOTIFICATIONS = NOTIFICATION_ROUTE+VERSION1+'/find-list'

//WRITE
export const GET_FIND_RECIPIENT= NOTIFICATION_ROUTE+VERSION1+'/find-recipient'
export const SEND_NOTIFICATION = NOTIFICATION_ROUTE+VERSION1+'/send'