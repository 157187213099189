import axios from 'axios'
import { CHANGE_WRITE_NOTIFICATION_FORM,
         GET_RECIPIENT,
         SAVE_NOTIFICATION,
         CLEAN_WRITER_fORM
} from "../types/type"
import { API_ENDPOINT } from "../config/config"
import { GET_FIND_RECIPIENT, SEND_NOTIFICATION } from './actionsUtilities/actionRoutes'


export const changeWriteNotificationForm = (key, value) => async (dispatch) => {
    dispatch({
      type: CHANGE_WRITE_NOTIFICATION_FORM,
      payload: {
        key,
        value
      }
    })
}

export const getRecipient = (body) => async (dispatch) => {
  const headers = { 'Authorization': localStorage.getItem('jwtToken') }
  await axios.post(
    `${API_ENDPOINT + GET_FIND_RECIPIENT}`,
    body,
    { headers }
  ).then(res => {
    dispatch({
      type: GET_RECIPIENT,
      payload: res.data.result
    })
  }).catch(console.error)
}

export const saveNotificationPush = (body, navigate) => async (dispatch) => {
  console.log(body,"body")
  const headers = { 'Authorization': localStorage.getItem('jwtToken') }
  await axios.post(
    `${API_ENDPOINT + SEND_NOTIFICATION}`,
    body,
    { headers }
  ).then(res => {
    dispatch({
      type: SAVE_NOTIFICATION,
      payload: res.data.result
    })
    if (navigate) {
        navigate('/sent')
    }
    dispatch({
      type: CLEAN_WRITER_fORM
    })
  }).catch(console.error)
}