/*Auth*/
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const VALIDATE_EMAIL_USER = 'VALIDATE_EMAIL_USER'

/*Modal*/
export const WRITE_NOTIFICATION_MODAL_OPEN = 'WRITE_NOTIFICATION_MODAL_OPEN'
export const WRITE_NOTIFICATION_MODAL_CLOSE = 'WRITE_NOTIFICATION_MODAL_CLOSE'

/*Sent*/
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'

/*Write*/
export const CHANGE_WRITE_NOTIFICATION_FORM = 'CHANGE_WRITE_NOTIFICATION_FORM'
export const GET_RECIPIENT = 'GET_RECIPIENT'
export const CHANGE_DATE_PREVIEW = 'CHANGE_DATE_PREVIEW'
export const SAVE_NOTIFICATION = 'SAVE_NOTIFICATION'
export const CLEAN_WRITER_fORM = 'CLEAN_WRITER_fORM'

/*Errors*/
export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const FORM_ERROR = 'FORM_ERROR'
