import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import { CheckBokWriter } from '../../Input/CheckBokWriter'
import { Container } from '@mui/material'
import { TextFieldC } from '../../Input/TextFieldC'
import { useDispatch, useSelector } from 'react-redux';
import { changeWriteNotificationForm, getRecipient } from '../../../actions/writeAction';

const Divider = styled(Container)`
  padding: 0px;
  margin: 0px;
`
export const WriterForm = () => {

  const { notificationForm } = useSelector((state) => state.writeReducer)
  const dispatch = useDispatch()
  const [ state, setState ] = useState(notificationForm)

  const toggle = (e) => {
    const name = e.target.id
    dispatch(changeWriteNotificationForm(
      [name], !notificationForm[name]
    ))
  }

  const onChange = e => {
    const name = e.target.id
    dispatch(changeWriteNotificationForm([name], e.target.value))
  }

  useEffect(() => {
    console.log(notificationForm.for.length)
    if(notificationForm.for.length >= 4){
      dispatch(getRecipient({"documentNumber": Math.floor(notificationForm.for)}))
    }
  }, [notificationForm.for])
  

  return (
    <Divider>
      <>
        <CheckBokWriter 
          checked={notificationForm.notificationSignature} 
          label={'Firma en notificación'} 
          id='notificationSignature'
          onChange={toggle}
          value={notificationForm.notificationSignature}  
        />
        <CheckBokWriter 
          checked={notificationForm.pushNotification}  
          label={'Notificación Push'}
          id='pushNotification'
          onChange={toggle}
          value={notificationForm.pushNotification}  
        />
        <CheckBokWriter 
          checked={notificationForm.readReceipt}
          label={'Confirmación de lectura'}
          id='readReceipt'
          onChange={toggle} 
          value={notificationForm.readReceipt}  
        />
      </>
      <br/>
      <TextFieldC
        variant="outlined"
        label="Para"
        fullWidth
        id='for'
        onChange={onChange} 
        value={notificationForm.for}  
      />
      <br/>
      <TextFieldC 
        variant="outlined"
        label="Asunto"
        fullWidth
        id='subject'
        onChange={onChange} 
        value={notificationForm.subject}  
      />
    </Divider>
  )
}
