import React from 'react'
import { styled } from "@mui/material/styles"
import { Button} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch } from 'react-redux'
import { openwriteNotificationModal } from '../../actions/modalAction'
import { useNavigate } from 'react-router-dom'

const ButtonW = styled(Button)`
  width: 213px;
  height: 47px;
  background: #2499EF;
  border-radius: 8px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;

  :hover {
    color: #2499EF;
    border: 1px solid #2499EF;
  }
`

export const ButtonWrite = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/write')
  }
  return (
    <ButtonW endIcon={<AddIcon />} onClick={handleClick}>
        Redactar
    </ButtonW>
  )
}
