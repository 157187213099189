import AppRoutes from "./routes/AppRoutes";
import { Provider} from 'react-redux'
import Store from './store/store'
import  './app.css'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react';

const store = Store()
const persist = persistStore(store);
function App() {
  return (
      <Provider store={store}>
      <PersistGate persistor={persist}>
        <AppRoutes/>
      </PersistGate>
      </Provider>
  );
}

export default App;
