import React from 'react'
import { styled } from "@mui/material/styles"
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import {ReactComponent as IconMyself} from '../../assets/Plantilla/iconMysefl.svg'

const NavBar = styled(AppBar)`
  background: #094876;
  height: 100px;
`

const Header = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <NavBar position="static">
        <Toolbar>
            <IconMyself style={{marginTop: '35px', marginLeft: '8%'}}/>
        </Toolbar>
      </NavBar>
    </Box>
  )
}

export default Header
