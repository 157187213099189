import { useSelector } from 'react-redux'
import { styled } from "@mui/material/styles"
import {Navigate, Outlet} from 'react-router-dom'
import { MenuLeft } from '../components/Menu/MenuLeft'
import { configMenu } from './menuRoutes'
import { Container } from '@mui/material'

const ContainerMenu = styled(Container)`
  display: contents;
`
const LeftMenuBar = styled(Container)`
  
`
const ProtectedRoutes = () => {

  const { isAuthenticated } = useSelector((state) => state.authReducer)
  const {userPermission} = useSelector((state) => state.authReducer.user)

  if(!isAuthenticated) {
    return <Navigate to="/"/>
  }
  
  return (
    <>
      <ContainerMenu>
        <LeftMenuBar>
          <MenuLeft
            menuRoutes={configMenu(userPermission)}
          />
        </LeftMenuBar>
      </ContainerMenu>
      <Outlet/>
    </>
  )
}

export default ProtectedRoutes