import { Container, TextField } from '@mui/material'
import { styled } from "@mui/material/styles"
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changeWriteNotificationForm } from '../../actions/writeAction';


const Divider = styled(Container)`
  margin-top: 10px;
`
const CssTextFieldArea = styled(TextField)({
  '.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
  },
    '& label.Mui-focused': {
      color: '#738499',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '15px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: "1px solid #E9EFF6",
        borderRadius: "8px",
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '15px',
      },
      '&:hover fieldset': {
        borderColor: '#738499',
        fontFamily: 'Montserrat',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#738499;',
        fontFamily: 'Montserrat',
      },
    },
  });

export const TextAreaFiel = () => {

  const { notificationForm } = useSelector((state) => state.writeReducer)
  const dispatch = useDispatch()

  const onChange = e => {
    const name = e.target.id
    dispatch(changeWriteNotificationForm([name], e.target.value))
  }

  return (
    <Divider>
        <CssTextFieldArea
            variant="outlined"
            placeholder='Escribe tu mensaje'
            multiline
            rows={20}
            maxRows={20}
            fullWidth
            id='description'
            onChange={onChange} 
            value={notificationForm.description}  
        />
    </Divider>
  )
}
