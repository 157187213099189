import {
    CHANGE_WRITE_NOTIFICATION_FORM,
    GET_RECIPIENT,
    CHANGE_DATE_PREVIEW, 
    SAVE_NOTIFICATION,
    CLEAN_WRITER_fORM
} from '../types/type'

const initialState = {
    notificationForm: {
        recipient: [],
        subject: '',
        for: '',
        description: '',
        notificationSignature: false,
        readReceipt: false,
        pushNotification: false,
        tags:{
          nombre:'[nombre]',
          apellido:'[apellido]',
          telefono:'[telefono]',
          localidad:'[localidad]'
        },
    },
    recipientData: {},
    datePreview: '',
    savedNotification: [],
}

export default (state = initialState, action) => {
  switch(action.type) {
    case CHANGE_WRITE_NOTIFICATION_FORM:
      return {
        ...state,
        notificationForm: {
          ...state.notificationForm,
          [action.payload.key]: action.payload.value
        }
      }
    case GET_RECIPIENT:
      return {
        ...state,
        recipientData: action.payload
      }
    case CHANGE_DATE_PREVIEW:
      return {
        ...state,
        datePreview: action.payload
      }
      case SAVE_NOTIFICATION:
        return {
          ...state,
          savedNotification: action.payload
        }
    case CLEAN_WRITER_fORM:
      return {
        notificationForm: {
        recipient: [],
        subject: '',
        for: '',
        description: '',
        notificationSignature: false,
        readReceipt: false,
        pushNotification: false,
        tags:{
          nombre:'[nombre]',
          apellido:'[apellido]',
          telefono:'[telefono]',
          localidad:'[localidad]'
        },
    },
    recipientData: {},
    datePreview: '',
    savedNotification: [],
    }
    default:
      return state
    }
}