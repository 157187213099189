import {
  GET_NOTIFICATIONS,
} from "../types/type";

const initialState = {
  notifications: [],
  elementsCount: 0,
  pagination:1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        pagination:action.payload.pagination,
        notifications: action.payload.notifications,
        elementsCount: action.payload.elementsCount,
      };
    default:
      state.reset = false;
      return state;
  }
};
