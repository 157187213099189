import React, { useEffect } from "react";
import SearchInput from "../../components/Input/SearchInput";
import { PageHeader } from "../../components/ui/PageHeader";
import { SendTabs } from "../../components/Send/SendTabs";
import { CustomTable } from "../../components/Tables/CustomTable";
import AppContent from "../../components/AppContainer/AppContent";
import { getNotifications } from "../../actions/sentAction";
import { useDispatch, useSelector } from "react-redux";

export const Sents = () => {

  const dispatch =  useDispatch()
  const {notifications} = useSelector((state)=>state.sentReducer)

  useEffect(() => {
    const body = {
      count: "10",
      pag: "1",
      sort: { _id: -1 }
    };
    getNotifications(body)(dispatch);
  }, [])

  return (
    <>
      <AppContent>
        
          <PageHeader title="Enviados" sx={{ mt: 2, mb:1 }}  iconName={"Telegram"} />
          <SendTabs sx={{ mb: 2 }} />
          <SearchInput
            disable_border="true"
            placeholder="Search"
            sx={{ mb: 0 }}
          />
          <CustomTable notifications = {notifications}/>
          
      </AppContent>
    </>
  );
};
