import * as React from "react"
import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"
import { ReactComponent as BannerImage } from '../../assets/Login/banner.svg'
import { ReactComponent as Logo } from '../../assets/Login/logo.svg'
import { LoginForm } from "../../components/Forms/Login/LoginForm"
import { Container } from "@mui/material"

const Divider = styled(Container)`
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
`
const GridLeft = styled(Grid)`
  background: #A7D1F1;
`
const GridRigth = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
export const Login = () => {

  return (
    <Grid container spacing={2}>
      <GridLeft xs={6}>
          <BannerImage />
      </GridLeft>
      <GridRigth xs={6}>
        <Divider>
          <Logo/>
        </Divider>
        <LoginForm/>
      </GridRigth>
    </Grid>
  )
}
