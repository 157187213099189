import {CLEAR_ERRORS, 
        FORM_ERROR, 
        GET_ERRORS, 
        LOGOUT_USER} from '../types/type.js'

const initialState = {
  errors: {},
  showError: false,
  errorsForm: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return initialState
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        showError: true
      }
    case FORM_ERROR:
      return {
        ...state,
        errorsForm: action.payload,
        showError: true
      }
    case CLEAR_ERRORS:
      return initialState
    default:
      return state
  }
}

