import { TableCell } from '@mui/material';
import { styled } from "@mui/material/styles";
import React from 'react'


const HeaderCell = styled(TableCell)`
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  border-bottom: none;
  background-color: #F5F8FA;
  color: #738499;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  &:last-child {
    text-align: center;
  }
`;

export const CustomHeader = ({text}) => {
  return (
    <HeaderCell>
        {text}
    </HeaderCell>
  )
}
