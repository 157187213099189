import React from 'react'
import AppContent from '../../components/AppContainer/AppContent'

export const Dashboard = () => {
  return (
    <AppContent>
      dashboard
    </AppContent>
  )
}
