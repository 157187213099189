import isEmpty from '../utils/isEmpty'
import {
    LOGOUT_USER,
    SET_CURRENT_USER, 
    VALIDATE_EMAIL_USER
} from '../types/type'

const initialState = {
    isAuthenticated: false,
    user: {},
    reset: false,
    loading: false,
    messageVerificate: ''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT_USER:
            return initialState
        case SET_CURRENT_USER:
            return {
              ...state,
              isAuthenticated: !isEmpty(action.payload),
              user: action.payload,
              loading: false
            }  
        case VALIDATE_EMAIL_USER:
            return {
              ...state,
              messageVerificate: action.payload
            }
        default:
            state.reset = false
            return state
    }
}