import { Box, Icon } from '@mui/material'
import React from 'react'
import styled from 'styled-components';
import Typography from "@mui/material/Typography";
import * as Icons from '@mui/icons-material';


export const PageHeader = ({title,sx,iconName}) => {
  const IconComponent = Icons[iconName];
  return (
    <>
        <Box display="flex" alignItems="center" sx={sx}>
          <IconComponent fontSize="large" style = {{color:"#45ABF6"}} />
          <Typography variant="h6" color="initial" pl={2} fontFamily={"'Montserrat'"} fontStyle={"normal"} fontSize={"14px"} fontWeight={600}>
            {title}
          </Typography>
        </Box>
      </>
  )
}