export const configMenu = (userPermission) => {
    const permissions = [
      { name: "WEB_LIST_NOTIFICATION", key: 1 },
      { name: "CREATE_NOTIFICATIONS", key: 2 },
      
    ]
    const mainRoutes = [
      {
        route: '/sent',
        name: 'sent',
        keysPermissions: [1, 2],
        title: "Enviados",
        component: '/sent',
        visible: false
      },
    ]
    
    if (userPermission) {
      let viewPermissions = permissions.filter(per => userPermission.includes(per.name))
  
      let lst = mainRoutes.filter(x => x.keysPermissions.map(key => viewPermissions.some(per => per.key === key)).every(x => x === true));
      mainRoutes.forEach((element) => lst.forEach(elmt => { if (element === elmt && element.visible !== 'none') element.visible = true }))
    }

    return mainRoutes.filter(route => route.visible === true || route.visible === "none")
  }