import React from 'react'
import { styled } from "@mui/material/styles"
import {ReactComponent as ErrorIcon} from '../../assets/ErrorMessage/error.svg'
import Box from '@mui/material/Box'

import { CustomComponent } from '../ui/CustomComponent'


const Container2 = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: -24%;
  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
`

export const CardError = () => {

  return (
    <>
        <Container2>
            <CustomComponent component={ErrorIcon} />
        </Container2>   
    </>
  )
}
