 export const getStatusFemName = (text) => {
    switch (text) {
      case "No leído":
        return "No leída";
      case "Completado":
        return "Completada";
      case "Pendiente":
        return "Pendiente";
      case "Rechazado":
        return "Rechazada";
      case "Error":
        return "Error";
      case "Novedad":
        return "Novedad";
      case "Leído":
        return "Leída";
      case "Rebotado":
        return "Rebotado";
      default:
        return 'Sin estado';
    }
  };