import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'


export const CustomTableFooter = ({pagination,elementsCount,showing}) => {
  return (
    <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    gap = {0.5}
  >
    
    <Typography variant='h5' fontSize={14} fontWeight={600} color={"#738499"} fontFamily={"'Montserrat'"} fontStyle={"normal"}>
    {pagination ===1? `${showing} de ${elementsCount}`:`${(10*(pagination-1))+1} - ${showing > 10 ?10*(pagination):(10*(pagination-1))+showing} de ${elementsCount}` }  
    
    </Typography>
  </Box>
  )
}
