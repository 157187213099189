import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { StateFlag } from "./StateFlag";
import moment from "moment/moment";
import { WidthFull } from "@mui/icons-material";

const GenericCell = styled(TableCell)`
  border: 0;
  height: 28px;
  font-size: 13px;
  font-weight: 500;
  color: #6b7280;
  font-family: 'Montserrat';
  font-style: normal;
  &:first-of-type".{
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
`;

const CustomTableRow = styled(TableRow)`
  border: 1px solid #E9EFF6
  height: auto;
  &:not(:last-child) {
    
  }
`;

const RemitentCell = styled(TableCell)`
  border: 0;
  height: 28px;
  font-size: 14px;
  font-weight: 600;
  color: #6b7280;
  font-family: 'Montserrat';
  font-style: normal;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
`;

const AffairCell = styled(TableCell)`
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: #6b7280;
  font-family: 'Montserrat';
  font-style: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 20%;
  
  
`;


export const CustomTableContent = ({ row }) => {

  
  const formatDate = () => (moment(row.notificationDate).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY'))
    ? moment(row.notificationDate).format('h:mm a') : moment(row.notificationDate).format('D MMM')
  
  return (
    <CustomTableRow
      sx={{
        backgroundColor: "background.paper",
        boxShadow: "0px 4px 10px 2px rgba(19, 80, 124, 0.06)",
        
        // cursor: rowClick ? "pointer" : "unset",
        cursor: "pointer",
        "& td:first-of-type": {
          borderLeft: "0",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        },
        "& td:last-of-type": {
          borderRight: "0",
          textAlign: "center",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        },
      }}
    >
        <RemitentCell sx={{py:3}}>{row.recipient ? row.recipient.firstName : 'No hay remitente'}</RemitentCell>
        <AffairCell >
          <div style={{ display: "flex", alignItems: "center"}}>
            <AttachFileIcon/>
            <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
              {row.subject}
            </span>
          </div>
        </AffairCell>
        <GenericCell><StateFlag text={row.currentStatus && row.currentStatus.name} /></GenericCell>
        <GenericCell>{formatDate()}</GenericCell>
    </CustomTableRow>
  );
};
