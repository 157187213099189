import React from 'react'
import { styled } from "@mui/material/styles"
import AppContent from '../../components/AppContainer/AppContent'
import { Button, Container, Grid, Modal, Typography } from '@mui/material'
import Paper from '@mui/material/Paper';
import { WriterForm } from '../../components/Forms/Writer/WriterForm'
import { PageHeader } from '../../components/ui/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { closewriteNotificationModal } from '../../actions/modalAction'
import {ReactComponent as PreviewSVG} from '../../assets/write/preview.svg'
import { TextAreaFiel } from '../../components/Input/TextAreaFiel'
import { saveNotificationPush } from '../../actions/writeAction';
import { useNavigate } from 'react-router-dom';

const Divider = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Divider2 = styled(Container)` 
  display: flex;
  justify-content: center;
`
const Text = styled(Typography)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #011E3D;
  margin-left: 13px;
`
const Title = styled(Typography)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #011E3D;
  margin-left: 23px;
  margin-top: 39px;
  margin-bottom: 25px;
`
const TextLabels = styled(Typography)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #011E3D;
  margin-left: 13px;
  margin-top: 16px;
  a
`
const Labels = styled(Typography)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #45ABF6;
  margin-left: 13px;
  margin-top: 16px;
`
const Title2 = styled(Typography)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #707070;
  margin-top: 31px;
  text-align: center;
`
const ButtonW = styled(Button)`
  width: 79px;
  height: 45px;
  background: #2499EF;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  position: absolute;
  margin-top: 10px;
  left: 50%;

  :hover {
    color: #2499EF;
    border: 1px solid #2499EF;
  }
`
export const Write = () => {

  const { notificationForm, recipientData } = useSelector((state) => state.writeReducer)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSend = () => {
    const body = {
      "requiredSignature": notificationForm.notificationSignature,
      "requiredConfirmationRead": notificationForm.pushNotification,
      "isPushNotification": notificationForm.readReceipt,
      "recipients": [
        {
          "documentNumber": recipientData.documentNumber,
          "firstName": recipientData.firstName,
          "secondName": recipientData.secondName,
          "surname": recipientData.surname,
          "secondSurname": recipientData.secondSurname,
          "fullName": recipientData.fullName
      }
      ],
      "subject": notificationForm.subject,
      "description": notificationForm.description,
      "attachments": []
     }
    dispatch(saveNotificationPush(body, navigate))
  }
  return (
    <AppContent>
        <PageHeader title="Redactar" sx={{ my: 2 }}  iconName={"MailOutline"} />
          <Paper elevation={1} width={100} sx={{height: '883px'}}>
          <Grid container>
            <Grid item xs={6}>
              <Title>
                Redactar nueva notificación
              </Title>
              <WriterForm/>
              <Divider>
                <TextLabels>Etiquetas:</TextLabels>
                <Labels>Nombre</Labels>
                <Labels>Apellido</Labels>
                <Labels>Telefono</Labels>
                <Labels>Localidad</Labels>
              </Divider>
              <TextAreaFiel/>
              <ButtonW onClick={handleSend}>Enviar</ButtonW>
            </Grid>
            <Grid item xs={6}>
              <Title2>Previsualización</Title2>
              <Divider2>
                <PreviewSVG/>
              </Divider2>
            </Grid>
          </Grid>
          </Paper>
    </AppContent>
  )
}
