import React from 'react'
import { styled } from "@mui/material/styles"
import { TextField } from '@mui/material'

const CssTextField = styled(TextField)({
  '.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
  },
    '& label.Mui-focused': {
      color: '#738499',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '15px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: "1px solid #E9EFF6",
        borderRadius: "8px",
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '15px',
      },
      '&:hover fieldset': {
        borderColor: '#738499',
        fontFamily: 'Montserrat',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#738499;',
        fontFamily: 'Montserrat',
      },
    },
  });

export const TextFieldC = (props) => {
  return (
    <CssTextField
      name={props.name}
      type={props.type} 
      label={props.label}
      variant={props.variant}
      value={props.value}
      id={props.id}
      onChange={props.onChange}
      fullWidth
      sx={{
        marginTop: "16px"
      }}
    />
  )
}
