import {
  WRITE_NOTIFICATION_MODAL_OPEN,
  WRITE_NOTIFICATION_MODAL_CLOSE
} from '../types/type'

const initialState = {
    writeModal: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case WRITE_NOTIFICATION_MODAL_OPEN:
            return {
              ...state,
              writeModal: true
            }
          case WRITE_NOTIFICATION_MODAL_CLOSE:
            return {
              ...state,
              writeModal: false
            }
        default:
            return state
    }
}