import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { fontFamily } from '@mui/system';
import { getStatusFemName } from '../../utils/getStatusFemName';

// const FlagBox = styled(Box)({
//     display: 'flex',
//     justifyContent: 'center',
//     padding: "4px ",
//     borderRadius: "4px",
//     fontWeight: 600,
//     fontSize: 12,
//     color: "#011E3D",
//   });

const getFlagColor = (text) => {
    switch (text) {
      case "No leído":
        return "#2499EF";
      case "Completado":
        return "#27CE88";
      case "Pendiente":
        return "#FFC675";
      case "Rechazado":
        return "#FE8969";
      case "Error":
        return "#FF316F";
      case "Novedad":
        return "#8C8DFF";
      case "Leído":
        return "#A7BEDA";
      case "Rebotado":
        return "#DCE5F0";
      default:
        return "#F5F8FA";
    }
  };

const FlagBox = styled(Box)({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px",
    borderRadius: "4px",
    fontWeight: 600,
    fontSize: 12,
    color: "#011E3D",
    paddingInline:10,
  });

export const StateFlag = ({text}) => {
  return (
    <FlagBox sx={{backgroundColor:getFlagColor(text)}}>
        {!!text? getStatusFemName( text ):'Sin estado'}   
    </FlagBox>
  )
}
