import { styled, TextField } from "@mui/material"
import React from "react"

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: '#878787',
    fontFamily: 'Montserrat',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: "1px solid #878787",
      borderRadius: "8px",
      fontFamily: 'Montserrat',
    },
    '&:hover fieldset': {
      borderColor: '#878787',
      fontFamily: 'Montserrat',
    },
    '&.Mui-focused fieldset': {
      color: '#878787',
      borderColor: '#878787;',
      fontFamily: 'Montserrat',
    },
  },
}));

const TextFieldComponent = (props) => {
  return <StyledTextField {...props} />;
};

export default TextFieldComponent